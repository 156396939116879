<template lang="pug">
  el-form(
    :model="formItems"
    label-position="top"
    size="mini"
    ref="form"
    :rules="rules"
  )
    el-row(
      v-for="(attributes, rowIndex) in formAttributes.rows"
      :key="rowIndex"
      :gutter="5"
    )
      el-col(
        v-for="(attribute, index) in attributes"
        :key="attribute.key"
        :span="24 / attributes.length"
      )
        el-card.inline-form__card(v-if="attribute.grouped" :header="attribute.title")
          el-col(
            v-for="(subattributes, subindex) in attribute.rows"
            :key="subattributes.key"
            :span="subattributes.span ? subattributes.span : 12"
            :offset="subattributes.offset ? subattributes.offset : 0"
          )
            template(v-if="subattributes.inputType === 'modal'")
              el-link(
                type="primary"
                @click="openModal()"
              ) {{ subattributes.label }}
            input-selector(v-else :inputModel="subattributes" :inputProp="subattributes.key")
        input-selector(v-else :inputModel="attribute" :inputProp="attribute.key")
    slot(name="actions")
</template>

<script>
import InputSelector from '@/components/forms/InputSelector.vue';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';

export default {
  name: 'InlineForm',
  props: ['formAttributes'],
  components: { InputSelector },
  methods: {
    openModal() {
      this.$emit('modal');
    },
    getInputProp(rowIndex, index) {
      return `rows.${rowIndex}.${index}.value`;
    },
  },
  computed: {
    items() {
      return flattenForm(this.formAttributes.rows);
    },
    formItems() {
      return formatForm(null, this.items);
    },
    rules() {
      const rules = {};
      this.items.forEach((item) => {
        rules[item.key] = [{ required: true, message: 'Es requerido', trigger: 'blur' }];
      });
      return rules;
    },
  },
};
</script>
