<template lang="pug">
  div
    el-row
      el-col
        h3.title Configuración de grupos - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchGroups"
            ) FILTRAR
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="groups"
          :loading="loading"
          :height="500"
          :shownEdit="false"
        )
          template(slot="additionalActions" slot-scope="scope")
            el-tooltip(
              v-if="canSeeButton()"
              content="Editar operador"
              placement="top"
              effect="light"
              :show-after="800"
            )
              el-button(
                type="warning"
                circle
                size="mini"
                icon="el-icon-edit"
                @click.prevent="handleUpdate(scope.row)"
              )
            el-tooltip(
              v-if="canSeeButton()"
              content="Datos de facturación"
              placement="top"
              effect="light"
              :show-after="800"
            )
              el-button(
                type="primary"
                circle
                size="mini"
                icon="el-icon-postcard"
                @click.prevent="openDialogInvoiceData(scope.row)"
              )
      el-dialog(title="Ver/Editar grupo" :visible.sync="dialogForm.visible" width="70%")
        inline-form(
          :formAttributes="dialogForm.attributes"
          ref="dialogForm"
          @modal="openDialogB2cOffsetHistoric"
        )
          template(slot="actions" slot-scope="scope")
            el-row.inline-form__actions
              el-button(@click="closeDialog()") Cancelar
              el-button(
                v-if="canSeeButton()"
                type="primary"
                @click.prevent="updateGroup()"
                :loading="submitLoading"
              ) Actualizar
      b2c-offset-historic-modal(
        :visible="dialogB2cOffsetHistorical"
        :data="this.dialogForm.data.b2c_offset_historic"
        @close="closeDialogB2cOffsetHistoric"
      )
      invoice-data-modal(
        :visible="dialogInvoiceData"
        :data="invoiceData"
        :country="this.selectedCountry"
        source="group"
        @close="closeDialogInvoiceData"
      )
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import InlineForm from '@/components/forms/InlineForm.vue';
import DataTable from '@/components/tables/DataTable.vue';
import B2cOffsetHistoricModal from '@/components/ui/B2cOffsetHistoricModal.vue';
import InvoiceDataModal from '@/components/ui/InvoiceDataModal.vue';

// eslint-disable-next-line import/no-cycle
import financeApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';

export default {
  name: 'Groups',
  components: {
    SelectInput,
    InlineForm,
    DataTable,
    B2cOffsetHistoricModal,
    InvoiceDataModal,
  },
  data() {
    return {
      headers: [
        { key: 'name', label: 'Nombre' },
        { key: 'bank_account.number', label: 'Número de cuenta' },
        { key: 'commission.value', label: '% Comisión', type: 'percentage' },
        { key: 'refund_factor.value', label: '% Devolución', type: 'percentage' },
        { key: 'b2c_offset', label: 'Cuota de transporte', type: 'percentage' },
      ],
      groups: [],
      search: { country: 'CL' },
      dialogForm: { visible: false, attributes: {}, data: {} },
      dialogB2cOffsetHistorical: false,
      selectedCountry: null,
      loading: false,
      submitLoading: false,
      dialogInvoiceData: false,
      invoiceData: {},
    };
  },
  beforeMount() {
    this.fetchGroups();
  },
  methods: {
    fetchGroups() {
      this.loading = true;
      financeApi.get_groups(this.search).then((data) => {
        this.groups = data.body;
        this.groups = data.body.map((group) => {
          if (group.b2c_offset !== null && group.b2c_offset !== undefined) {
            // eslint-disable-next-line
            group.b2c_offset = ((group.b2c_offset - 1) * 100).toFixed(1);
          }
          return group;
        });
        this.loading = false;
      }).catch(() => { this.loading = false; });
      this.selectedCountry = this.search.country;
    },
    handleUpdate(data) {
      const attributes = { ...this.formAttributes };
      const rows = flattenForm(attributes.rows);
      // eslint-disable-next-line
      rows.forEach((row) => row.value = this._.get(data, row.key));
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = { ...data };
      const comission = this.dialogForm.attributes.rows[0][1];
      comission.value = this.textCommission();
      const transferPolicy = this.dialogForm.data.transfer_policy_type;
      const businessDayValue = this.dialogForm.attributes.rows[3][0].rows[3];
      const monthlyTimesValue = this.dialogForm.attributes.rows[3][0].rows[2];
      const weekDayValue = this.dialogForm.attributes.rows[3][0].rows[4];
      const conciliationConverter = this.dialogForm.data.conciliation_converter;
      const conciliationCurrency = this.dialogForm.attributes.rows[6][0].rows[1];
      switch (transferPolicy) {
        case 'business_day':
          businessDayValue.value = this.dialogForm.data.transfer_policy.value;
          businessDayValue.visible = false;
          monthlyTimesValue.visible = true;
          weekDayValue.visible = true;
          break;
        case 'monthly_times':
          businessDayValue.value = this.dialogForm.data.transfer_policy.business_days;
          businessDayValue.visible = false;
          monthlyTimesValue.value = this.dialogForm.data.transfer_policy.value;
          monthlyTimesValue.visible = false;
          weekDayValue.visible = true;
          break;
        case 'fixed':
          weekDayValue.value = this.dialogForm.data.transfer_policy.value;
          weekDayValue.visible = false;
          businessDayValue.visible = true;
          monthlyTimesValue.visible = true;
          break;
        case 'weekly':
          weekDayValue.value = this.dialogForm.data.transfer_policy.accountable_day;
          weekDayValue.visible = false;
          businessDayValue.visible = true;
          monthlyTimesValue.visible = true;
          break;
        default: // Do nothing
      }

      conciliationCurrency.visible = !conciliationConverter;
      this.dialogForm.visible = true;
    },
    closeDialog() {
      this.dialogForm.visible = false;
    },
    updateGroup() {
      this.startSubmitLoading();
      this.validateForm();
      if (this.validForm) {
        this.$confirm('¿Estás seguro de que deseas modificar la información del grupo?', '¡Cuidado!', {
          confirmButtonText: 'SÍ',
          cancelButtonText: 'NO',
          type: 'warning',
        }).then(() => {
          const { id } = this.dialogForm.data;
          const bankAccount = this.dialogForm.data.bank_account;
          const attributes = formatForm(id, flattenForm(this.dialogForm.attributes.rows));
          if (parseFloat(attributes.b2c_offset) !== parseFloat(this.dialogForm.data.b2c_offset)) {
            this.$confirm('El valor modificado de cuota de transporte se aplicará en la página de forma inmediata', '¡Cuidado!', {
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Atrás',
              type: 'warning',
            }).then(() => {
              this.endPointUpdateGroup(attributes, id, bankAccount.id, 'Sincronización completada');
            }).catch(() => { this.stopSubmitLoading(); });
          } else {
            this.endPointUpdateGroup(attributes, id, bankAccount.id, 'Grupo actualizado correctamente');
          }
        }).catch(() => { this.stopSubmitLoading(); });
      } else {
        this.stopSubmitLoading();
      }
    },
    endPointUpdateGroup(attributes, groupId, bankAccountId, message) {
      financeApi.update_group(attributes, groupId, bankAccountId).then(() => {
        this.closeDialog();
        this.fetchGroups();
        this.$notify({
          title: 'Correcto',
          message,
          type: 'success',
        });
        this.stopSubmitLoading();
      }).catch(() => { this.stopSubmitLoading('Sincronización Fallida'); });
    },
    validateInput(input) {
      if (input === undefined || input === null || input === '') {
        return false;
      }
      return true;
    },
    validateForm() {
      const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
      this.validForm = true;

      if (this.validateInput(attributes.bank_account.holder) === false
          || this.validateInput(attributes.bank_account.bank_name) === false
          || this.validateInput(attributes.bank_account.national_id) === false
          || this.validateInput(attributes.bank_account.account_type) === false
          || this.validateInput(attributes.bank_account.number) === false
          || this.validateInput(attributes.bank_account.mail) === false) {
        this.validForm = false;
        this.$notify({
          title: 'Datos Bancarios incompletos',
          message: 'Revisa los datos bancarios, todos los campos son requeridos',
          type: 'error',
        });
      }

      if (this.validateInput(attributes.b2c_offset) === false) {
        this.validForm = false;
        this.$notify({
          title: 'Sobrecarga B2C',
          message: '% Cuota de transporte no puede estar vacío',
          type: 'error',
        });
      }

      if (attributes.transfer_policy_type === 'business_day' && this.validateInput(attributes.business_day_value) === false) {
        this.validForm = false;
        this.$notify({
          title: 'Política de transferencia incompleta',
          message: 'Debe seleccionar la Cantidad de días habiles',
          type: 'error',
        });
      }

      if (attributes.transfer_policy_type === 'monthly_times' && (this.validateInput(attributes.business_day_value) === false
          || this.validateInput(attributes.monthly_times_value) === false)) {
        this.validForm = false;
        this.$notify({
          title: 'Política de transferencia incompleta',
          message: 'Debe seleccionar Cuantas veces al mes y Cantidad de días habiles',
          type: 'error',
        });
      }

      if ((attributes.transfer_policy_type === 'fixed' || attributes.transfer_policy_type === 'weekly')
          && this.validateInput(attributes.weekly_day_value) === false) {
        this.validForm = false;
        this.$notify({
          title: 'Política de transferencia incompleta',
          message: 'Debe seleccionar el Día de la semana',
          type: 'error',
        });
      }

      if (this.validateInput(attributes.netsuite_vendor_id) === false
          || this.validateInput(attributes.netsuite_company_name) === false) {
        this.validForm = false;
        this.$notify({
          title: 'Datos Netsuite incompletos',
          message: 'Revisa los datos Netsuite, todos los campos son requeridos',
          type: 'error',
        });
      }

      if (attributes.conciliation_converter === true
          && this.validateInput(attributes.conciliation_currency) === false) {
        this.validForm = false;
        this.$notify({
          title: 'Datos de conciliación multimoneda incompletos',
          message: 'Debe seleccionar la moneda de conciliación',
          type: 'error',
        });
      }
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading(message) {
      if (message !== undefined) { this.$notify.error({ title: 'Error', message }); }
      this.submitLoading = false;
    },
    textCommission() {
      const { commission } = this.dialogForm.data;
      if (!commission) { return ''; }

      return `${commission.value}% desde el ${DateService.getDate(commission.from, 'DD MMMM YYYY')}`;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
    openDialogB2cOffsetHistoric() {
      this.dialogB2cOffsetHistorical = true;
    },
    closeDialogB2cOffsetHistoric() {
      this.dialogB2cOffsetHistorical = false;
    },
    openDialogInvoiceData(data) {
      this.invoiceData = data;
      this.dialogInvoiceData = true;
    },
    closeDialogInvoiceData() {
      this.fetchGroups();
      this.dialogInvoiceData = false;
    },
    isRejectedMulticurrency() {
      return this.selectedCountry === 'MX';
    },
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    accountTypes() {
      return DataService.get_account_types();
    },
    banks() {
      return DataService.get_banks();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    transferPolicy() {
      return DataService.TransferPolicyTypes();
    },
    weekDays() {
      return DataService.WeekDays();
    },
    currencies() {
      const currencies = DataService.ConciliationCurrencies();
      return currencies.map((currency) => ({ key: currency, value: currency }));
    },
    formAttributes() {
      const $this = this;
      return {
        rows: [
          [
            {
              key: 'name', value: null, inputType: 'description', label: 'Nombre',
            },
            {
              key: 'comision', value: null, inputType: 'description', label: 'Comisión',
            },
          ],
          [
            {
              title: 'Datos Bancarios',
              grouped: true,
              rows: [
                {
                  key: 'bank_account.holder', value: null, inputType: 'string', label: 'Titular',
                },
                {
                  key: 'bank_account.bank_name', value: null, inputType: 'select', label: 'Banco', options: this.banks, simple: true,
                },
                {
                  key: 'bank_account.national_id', value: null, inputType: 'string', label: 'RUT',
                },
                {
                  key: 'bank_account.account_type', value: null, inputType: 'select', label: 'Tipo de cuenta', options: this.accountTypes,
                },
                {
                  key: 'bank_account.number', value: null, inputType: 'string', label: 'Número de cuenta',
                },
                {
                  key: 'bank_account.mail', value: null, inputType: 'string', label: 'Correo de transferencia',
                },
              ],
            },
          ],
          [
            {
              title: 'Correos',
              grouped: true,
              rows: [
                {
                  key: 'conciliation_emails',
                  value: '',
                  inputType: 'select',
                  multiple: true,
                  label: 'Correos de conciliación',
                  allowCreate: true,
                  optionKey: 'id',
                },
                {
                  key: 'discount_emails',
                  value: '',
                  inputType: 'select',
                  multiple: true,
                  label: 'Correos de descuentos',
                  allowCreate: true,
                  optionKey: 'id',
                },
              ],
            },
          ],
          [
            {
              title: 'Política de transferencia',
              grouped: true,
              rows: [
                {
                  key: 'transfer_policy_text', value: null, inputType: 'description', label: 'Política Actual',
                },
                {
                  key: 'transfer_policy_type',
                  value: null,
                  inputType: 'select',
                  label: 'Tipo de politica',
                  options: this.transferPolicy,
                  inputTooltip: true,
                  toolTipLabel: 'Si la política de transferencia no se encuentra entre las opciones enviar un soporte con cambio de política especial',
                  changeMethod(value) {
                    const businessDayValue = $this.dialogForm.attributes.rows[3][0].rows[3];
                    const monthlyTimesValue = $this.dialogForm.attributes.rows[3][0].rows[2];
                    const weeklyDayValue = $this.dialogForm.attributes.rows[3][0].rows[4];
                    switch (value) {
                      case 'business_day':
                        businessDayValue.visible = false;
                        monthlyTimesValue.visible = true;
                        weeklyDayValue.visible = true;
                        break;
                      case 'monthly_times':
                        monthlyTimesValue.visible = false;
                        businessDayValue.visible = false;
                        weeklyDayValue.visible = true;
                        break;
                      case 'fixed':
                      case 'weekly':
                        monthlyTimesValue.visible = true;
                        businessDayValue.visible = true;
                        weeklyDayValue.visible = false;
                        break;
                      default: // Do nothing
                    }
                  },
                },
                {
                  key: 'monthly_times_value', value: null, inputType: 'number', label: 'Cuantas Veces al mes', precision: 0, step: 1, visible: true,
                },
                {
                  key: 'business_day_value', value: null, inputType: 'number', label: 'Cantidad de días hábiles', precision: 0, step: 1, visible: true,
                },
                {
                  key: 'weekly_day_value', value: null, inputType: 'select', label: 'Día de la semana', options: this.weekDays, visible: true,
                },
              ],
            },
          ],
          [
            {
              title: 'Sobrecarga B2C en Ibilbidea',
              grouped: true,
              rows: [
                {
                  key: 'b2c_offset', value: null, inputType: 'number', precision: 1, step: 1, minimum: 0, maximum: 100, label: '% Cuota de transporte',
                },
                { inputType: 'modal', label: 'Ver historial' },
              ],
            },
          ],
          [
            {
              title: 'Netsuite',
              grouped: true,
              rows: [
                {
                  key: 'netsuite_vendor_id', value: null, inputType: 'string', label: 'Vendor ID',
                },
                {
                  key: 'netsuite_company_name', value: null, inputType: 'string', label: 'Nombre de la Compañía',
                },
              ],
            },
          ],
          [
            {
              title: null,
              grouped: true,
              rows: [
                {
                  key: 'conciliation_converter',
                  value: null,
                  inputType: 'switch',
                  label: 'Conciliación Multimoneda',
                  divider: true,
                  span: 24,
                  disabled: this.isRejectedMulticurrency(),
                  change(value) {
                    const currencyValue = $this.dialogForm.attributes.rows[6][0].rows[1];
                    switch (value) {
                      case true:
                        currencyValue.visible = false;
                        break;
                      default:
                        currencyValue.visible = true;
                    }
                  },
                },
                {
                  key: 'conciliation_currency',
                  value: null,
                  inputType: 'select',
                  label: 'Moneda',
                  options: this.currencies,
                  visible: true,
                  offset: 12,
                },
              ],
            },
          ],
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .group-form {
    &__row {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &__card {
      margin-left: 5px;
    }
  }
  .el-tooltip {
    padding: 10px !important;
  }
  .inline-form__actions {
    margin-top: 10px;
  }
</style>
