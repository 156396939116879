<template lang="pug">
  div
    el-row
      el-col
        h3.title
          | Transferencias Pendientes Scotiabank - {{ selectedCountryName }}
          | - {{ batchTransferenceStatusText }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores:")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Por Día de transferencia:")
            date-input(:inputModel="search")
          el-form-item(label="Transferencias entre:")
            select-input(
              :options="filterBetweenAmount"
              :attribute="search"
              modelKey="betweenAmount"
              :changeMethod="validateExportScotia"
            )
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchPendingTransfers()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton() && selectedCountry === 'CL'"
              type="success"
              icon="el-icon-download"
              @click.prevent="handleExportScotiabank"
              :disabled="disabledExportScotia"
            ) EXPORTAR SCOTIA
            el-button(
              v-if="canSeeButton() && selectedCountry === 'CL'"
              type="warning"
              icon="el-icon-upload2"
              @click.prevent="handleImportScotiabank"
              :disabled="canImportScotiabank"
            ) IMPORTAR SCOTIA
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :summaries="summaries"
          :height="500"
          :shownSummary="true"
          :loading="loading"
          :shownEdit="false"
          @cellClickEvent="handleCellClick"
          refTable="pendingTransfersTable"
          :stripe="false"
          :shownActions="showActions"
          :shownSelection="true"
          @select="handleSelectionChange"
        )
          template(slot="additionalActions" slot-scope="scope")
            template(v-if="shownDiscountActions(scope.row)")
              a.el-button.el-button--primary.el-button--mini(
                :href="mailerDiscountPreview(scope.row)"
                @click.stop="showMailerDiscountPreview(scope.row)"
                target="_blank"
              )
                i(class="el-icon-view")
              el-button(
                type="info"
                icon="el-icon-message"
                size="mini"
                @click="sendMailerDiscount(scope.row)"
                :disabled="scope.row.disabled || scope.row.can_be_sent"
              )
    el-dialog(
      title="Subir archivo XLS de Scotiabank"
      :visible.sync="uploadDialog.visible"
      :close-on-click-modal="false"
      :show-close="false"
    )
      el-form(:inline="true" :model="uploadDialog" :rules="uploadFormRules" ref="form")
        el-row
          el-col
            el-form-item(label="Selecciona un archivo XLS:" prop="files")
              el-upload(
                v-model="uploadDialog.files"
                action=""
                accept=".xls"
                :file-list="uploadDialog.files"
                :auto-upload="false"
                :on-change="handleChangeFile"
              )
                el-button(slot="trigger" size="small" type="primary") Buscar
        el-row.inline-form__actions
          el-button(@click="closeUploadDialog()") Cancelar
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="uploadScotiabankFile()"
            :loading="submitLoading"
          ) Subir
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';
import pendingTransfers from '@/mixins/pendingTransfers';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'PendingTransfersScotiabank',
  components: {
    SelectInput, DataTable, DateInput,
  },
  mixins: [
    conciliables, pendingTransfers,
  ],
  data() {
    return {
      items: [],
      summaries: [],
      search: {
        country: 'CL',
        operators: [],
        date: DateService.getDate(),
        betweenAmount: 'all',
        bank: 'SCOTIABANK',
      },
      operators: [],
      batchTransference: null,
      batchTransferences: [],
      disabledExportScotia: true,
      selectedCountry: null,
      loading: false,
      uploadDialog: {
        visible: false,
        confirmDate: false,
        confirmFilename: false,
        files: [],
      },
      submitLoading: false,
      channelMessage: '',
    };
  },
  beforeMount() {
    this.fetchPendingTransfers();
  },
  methods: {
    fetchPendingTransfers() {
      this.loading = true;
      if (this.search.betweenAmount !== 'all') {
        this.search.operators = [];
      }

      FinanceApi.get_pending_transfers(this.search).then((data) => {
        this.items = data.body.pending_transfers.map((pendingTransfer) => ({
          ...pendingTransfer,
          disabled: true,
        }));
        this.batchTransference = data.body.batch_transference;
        this.batchTransferences = data.body.batch_transferences;
        this.validateExportScotia();
        this.loading = false;
        this.summaries = data.body.summary;
        const emptyBankData = data.body.empty_bank_data;
        if (emptyBankData.length > 0) {
          const emptyBankDataList = emptyBankData.join(', ');
          this.$notify.warning({ title: '¡Cuidado!', message: `Hay operadores o grupos sin datos bancarios: ${emptyBankDataList}` });
        }
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
      this.selectedCountry = this.search.country;
    },
    mailerDiscountPreview(row) {
      return FinanceApi.url_mailer_discount_preview(row.operator, this.search.date);
    },
    showMailerDiscountPreview(row) {
      // eslint-disable-next-line no-param-reassign
      row.disabled = false;
      this.$notify({
        title: 'Correo previsualizado',
        message: 'Correo previsualizado exitosamente, listo para ser enviado',
        type: 'success',
      });
    },
    sendMailerDiscount(row) {
      FinanceApi.send_mailer_discount(row.operator, this.search.date).then(() => {
        this.fetchPendingTransfers();
        this.$notify({
          title: 'Mail Enviado',
          message: 'Mail enviado exitosamente',
          type: 'success',
        });
      });
    },
    handleImportScotiabank() {
      const validState = this.batchTransferenceStatus === 'xls_processed' || this.batchTransferenceStatus === 'xls_uploaded';
      if (validState && !this.uploadDialog.confirmDate) {
        this.uploadDialog.visible = false;
        this.confirmDateMessage();
        this.stopSubmitLoading();
        return;
      }

      if (this.batchTransferenceCompleted) {
        this.xlsCompletedMessage();
      } else if (!this.batchTransference) {
        this.$alert('No has descargado el txt para el día seleccionado', 'Cuidado', {
          showConfirmButton: false,
        });
      } else {
        this.uploadDialog.visible = true;
      }
    },
    handleChangeFile(file) {
      // eslint-disable-next-line prefer-destructuring
      this.uploadDialog.files = [file.raw];
    },
    uploadScotiabankFile() {
      this.startSubmitLoading();
      const { form } = this.$refs;

      form.validate((valid) => {
        if (valid) {
          const params = { filename: this.uploadDialog.files[0].name };

          FinanceApi.historic_filename_import(params).then((data) => {
            if (!this.uploadDialog.confirmFilename && data.body > 0) {
              this.uploadDialog.visible = false;
              this.confirmFilenameMessage();
              this.stopSubmitLoading();
            } else {
              const formData = new FormData();
              formData.append('id', this.batchTransference.id);
              formData.append('file', this.uploadDialog.files[0]);

              FinanceApi.import_scotiabank(formData).then(() => {
                this.closeUploadDialog();
                this.$notify({
                  title: 'Carga exitosa.',
                  message: 'El archivo se ha subido con éxito.',
                  type: 'success',
                });
                this.fetchPendingTransfers();
                this.stopSubmitLoading();
              }).catch((exception) => {
                this.$notify.error({ title: 'Archivo inválido.', message: exception.body.message });
                this.stopSubmitLoading();
              });
            }
          }).catch((exception) => {
            this.$notify.error({ title: 'Error al validar nombre de archivo.', message: exception.body.message });
            this.stopSubmitLoading();
          });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    confirmDateMessage() {
      const message = `Hoy ya fue importado por ${this.batchTransference.user.name} a las
          ${this.batchTransference.updated_time}. ¿Estás seguro que quieres importar de nuevo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.uploadDialog.confirmDate = true;
        this.uploadDialog.visible = true;
      }).catch(() => {
        this.uploadDialog.files = [];
      });
    },
    confirmFilenameMessage() {
      const message = `El archivo ${this.uploadDialog.files[0].name} ya fue importado por
        ${this.batchTransference.user.name} el dia ${this.batchTransference.updated_date}.
        ¿Estás seguro que quieres importar de nuevo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.uploadDialog.confirmFilename = true;
        this.uploadDialog.visible = true;
      }).catch(() => {
        this.uploadDialog.files = [];
        this.uploadDialog.visible = true;
      });
    },
    handleExportScotiabank() {
      if (this.batchTransferenceStatus === 'one_to_thirty_text_downloaded' && this.search.betweenAmount === 'one_to_thirty_millions') { return this.textDownloadedMessage(); }
      if (this.batchTransferenceStatus === 'greater_than_thirty_text_downloaded' && this.search.betweenAmount === 'greater_than_thirty_millions') { return this.textDownloadedMessage(); }
      return this.exportScotiabank();
    },
    closeUploadDialog() {
      this.uploadDialog.visible = false;
      this.uploadDialog.confirmDate = false;
      this.uploadDialog.confirmFilename = false;
      this.uploadDialog.files = [];
    },
    xlsCompletedMessage() {
      this.$alert('La carga de la nómina está completa para el día seleccionado', 'No existen transferencias pendientes', {
        showConfirmButton: false,
      });
    },
    textDownloadedMessage() {
      const message = `Ya fue exportado por ${this.batchTransference.user.name} a las
          ${this.batchTransference.updated_time}. ¿Estás seguro que quieres exportar de nuevo?`;
      this.$confirm(message, '¡Cuidado!', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        type: 'warning',
      }).then(() => {
        this.exportScotiabank();
      }).catch(() => {});
    },
    async exportScotiabank() {
      await FinanceApi.export_scotiabank(this.search).then(() => {
        this.channelMessage = this.$message({
          type: 'success',
          message: 'Se está generando el archivo de texto, esto puede tardar unos minutos. Al cerrar el navegador o pestaña se perderá la descarga',
          duration: 0,
          showClose: false,
        });
      });
    },
    shownDiscountActions(row) {
      return row.discounts !== 0;
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    validateExportScotia() {
      if (this.search.betweenAmount === 'one_to_thirty_millions' || this.search.betweenAmount === 'greater_than_thirty_millions') {
        if (this.batchTransferences.length > 0) {
          const batchTransference = this.batchTransferences.find((element) => (
            element.between_amount_type === this.search.betweenAmount
          ));
          if (batchTransference) {
            this.batchTransference = batchTransference;
          } else { this.batchTransference = {}; }
        }
        this.disabledExportScotia = false;
      } else {
        this.disabledExportScotia = true;
        this.batchTransference = {};
      }
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    headers() {
      return [
        { key: 'operator', label: 'Operador' },
        { key: 'national_id', label: 'Rut' },
        { key: 'holder', label: 'Titular de la cuenta' },
        { key: 'bank_name', label: 'Banco' },
        { key: 'number', label: 'Numero de Cuenta' },
        { key: 'dates_to_transfer', label: 'Fechas a transferir' },
        {
          key: 'to_transfer', label: 'A Transferir', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'pendings', label: 'Pendientes', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'discounts', label: 'Descuentos', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'calculated_to_transfer', label: 'A Transferir con descuentos y pendientes', type: 'currency', country: this.selectedCountry,
        },
        {
          key: 'transfer_amount', label: 'Transferido', type: 'currency', country: this.selectedCountry,
        },
      ];
    },
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    batchTransferenceStatus() {
      return this._.get(this.batchTransference, 'status');
    },
    batchTransferenceStatusText() {
      switch (this.batchTransferenceStatus) {
        case 'pdf_uploaded':
          return 'Subiendo Scotiabank 🔄';
        case 'text_downloaded':
          return 'Archivo Descargado';
        default:
          return '';
      }
    },
    batchTransferenceCompleted() {
      return this.batchTransferenceStatus === 'pdf_processed' && this.batchTransference.failed_count === 0;
    },
    canImportScotiabank() {
      if (!this._.get(this.batchTransference, 'status')) { return true; }

      if (this.batchTransference && this.batchTransferenceCompleted) { return true; }

      return false;
    },
    showActions() {
      return this.items.some((item) => item.discounts !== 0);
    },
    uploadFormRules() {
      return {
        files: [{ required: true, message: 'Es requerido', trigger: 'blur' }],
      };
    },
    filterBetweenAmount() {
      return [
        { key: 'less_than_or_equal_zero', value: 'Menor o igual a cero' },
        { key: 'one_to_thirty_millions', value: '$1 a $30 millones' },
        { key: 'greater_than_thirty_millions', value: 'Mayores a $30 millones' },
        { key: 'all', value: 'Todos' },
      ];
    },
  },
  channels: {
    PrivateChannel: {
      connected() { },
      rejected() { },
      received(data) {
        if (data.status === 'error') {
          setTimeout(() => {
            this.channelMessage.close();

            this.$message({
              type: 'error',
              message: 'Falló la descarga',
              duration: 7000,
              showClose: false,
            });
          }, 3000);
        } else {
          const blob = new Blob([data.file_content], { type: 'text/plain' });

          const simulatedResponse = {
            blob: async () => blob,
            headers: {
              get: (header) => {
                if (header === 'content-disposition') {
                  return `attachment; filename="${data.filename}.txt"`;
                }
                return null;
              },
            },
          };

          handleDownload(simulatedResponse);
          this.fetchPendingTransfers();
          setTimeout(() => {
            this.channelMessage.close();
          }, 3000);
        }
      },
      disconnected() { },
    },
  },
  mounted() {
    this.$cable.subscribe({ channel: 'PrivateChannel', user_email: store.state.user.email, origin: 'export_scotia' });
  },
  destroyed() {
    const identifier = JSON.stringify({
      channel: 'PrivateChannel',
      user_email: store.state.user.email,
      origin: 'export_scotia',
    });
    /* eslint-disable no-underscore-dangle */
    const subscription = this.$cable._cable.subscriptions.subscriptions
      .find((sub) => sub.identifier === identifier);
    /* eslint-enable no-underscore-dangle */
    subscription.unsubscribe();
  },
};
</script>
