<template lang="pug">
  div
    el-row
      el-col
        h3.title Conciliaciones - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores:")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Mes:")
            date-input(:inputModel="search" type="month")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchConciliations()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportConciliations()"
            ) Exportar
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="items"
          :summaries="summaries"
          :shownActions="false"
          :shownSummary="true"
          :loading="loading"
          @cellClickEvent="handleCellClick"
          :height="500"
          refTable="conciliationsTable"
          :noInformation="true"
        )
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

import store from '@/store';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'Conciliations',
  components: {
    SelectInput, DataTable, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      selectedCountry: null,
      search: {
        country: 'CL',
        operators: [],
        date: DateService.getDate(),
      },
      headers: [],
      items: [],
      summaries: {},
      loading: false,
      channelMessage: '',
    };
  },
  beforeMount() {
    this.fetchConciliations();
  },
  methods: {
    fetchConciliations() {
      this.loading = true;
      FinanceApi.get_conciliations(this.search, false).then((data) => {
        this.headers = data.body.columns.map((header) => {
          if (header.key === 'operator') { return { ...header, type: 'object', fixed: true }; }

          return { ...header, type: 'currency', country: this.selectedCountry };
        });
        this.items = data.body.data.map((item) => {
          this._.forEach(item, (value, key) => {
            if (key === 'operator') {
              // eslint-disable-next-line no-param-reassign
              item[key] = { value, click: true, cellClass: 'data-table__click-row' };
            }
          });
          return item;
        });
        data.body.summary.forEach((item) => {
          this.summaries[item.key] = item.value;
        });
        this.loading = false;
      }).catch((exception) => {
        this.loading = false;
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });

      this.selectedCountry = this.search.country;
    },
    handleCellClick({ column, row }) {
      const columnName = column.property;

      if (columnName === 'operator') {
        store.commit('selectTotal', row.id);
        this.$router.push({ name: 'conciliation', params: { id: row.id } });
      }
    },
    exportConciliations() {
      FinanceApi.get_conciliations(this.search, true).then(() => {
        this.channelMessage = this.$message({
          type: 'success',
          message: 'Se está generando el archivo CSV, esto puede tardar unos minutos. Al cerrar el navegador o pestaña se perderá la descarga',
          duration: 0,
          showClose: false,
        });
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception });
      });
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  channels: {
    PrivateChannel: {
      connected() { },
      rejected() { },
      received(data) {
        if (data.status === 'error') {
          setTimeout(() => {
            this.channelMessage.close();

            this.$message({
              type: 'error',
              message: 'Falló la descarga',
              duration: 7000,
              showClose: false,
            });
          }, 3000);
        } else {
          const blob = new Blob([data.file_content], { type: 'text/csv' });
          const simulatedResponse = {
            blob: async () => blob,
            headers: {
              get: (header) => {
                if (header === 'content-disposition') {
                  return `attachment; filename="${data.filename}.csv"`;
                }
                return null;
              },
            },
          };
          handleDownload(simulatedResponse);

          setTimeout(() => {
            this.channelMessage.close();
          }, 3000);
        }
      },
      disconnected() { },
    },
  },
  mounted() {
    this.$cable.subscribe({ channel: 'PrivateChannel', user_email: store.state.user.email, origin: 'conciliations' });
  },
  destroyed() {
    const identifier = JSON.stringify({
      channel: 'PrivateChannel',
      user_email: store.state.user.email,
      origin: 'conciliations',
    });
    /* eslint-disable no-underscore-dangle */
    const subscription = this.$cable._cable.subscriptions.subscriptions
      .find((sub) => sub.identifier === identifier);
    /* eslint-enable no-underscore-dangle */
    subscription.unsubscribe();
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
  },
};
</script>
