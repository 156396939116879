export default {
  get_countries() {
    const countries = [
      { key: 'CL', value: 'Chile' },
      { key: 'PE', value: 'Perú' },
      { key: 'MX', value: 'México' },
      { key: 'US', value: 'USA' },
    ];
    return countries;
  },
  get_payment_types() {
    const paymentTypes = [
      { key: 'WebPay', value: 'WebPay', countries: ['CL'] },
      { key: 'PayPal', value: 'PayPal', countries: ['CL'] },
      { key: 'OneClick', value: 'OneClick', countries: ['CL'] },
      { key: 'Fintoc', value: 'Fintoc', countries: ['CL'] },
      { key: 'Payu', value: 'PayU', countries: ['PE'] },
      { key: 'Payu', value: 'Payu', countries: ['MX'] },
      { key: 'Cybersource', value: 'Cybersource', countries: ['MX'] },
      { key: 'Agencia', value: 'Agencia', countries: ['CL'] },
      { key: 'b2c', value: 'Ventas B2C', countries: ['CL'] },
    ];
    return paymentTypes;
  },
  get_payment_forms() {
    const paymentForms = [
      { key: 'Débito', value: 'Débito', agency: false },
      { key: 'Crédito', value: 'Crédito', agency: false },
      { key: 'No disponible', value: 'No Disponible', agency: true },
    ];
    return paymentForms;
  },
  get_sales_channel_mediums() {
    const salesChannelMediums = [
      { key: 'web', value: 'Web' },
      { key: 'api', value: 'Api' },
      { key: 'app', value: 'App' },
      { key: 'web_new', value: 'Web New' },
    ];
    return salesChannelMediums;
  },
  get_transbank_status() {
    const transbankStatus = [
      { key: 0, value: 'Abierto' },
      { key: 1, value: 'Cerrado' },
      { key: 2, value: 'Enviado', comment: 'Comunicado a transbank' },
      { key: 3, value: 'Resuelto', comment: 'Aceptado por transbank' },
    ];
    return transbankStatus;
  },
  get_missing_in() {
    const missingIn = [
      { key: 0, value: 'Recorrido' },
      { key: 1, value: 'Transbank' },
      { key: 2, value: 'Matched' },
    ];
    return missingIn;
  },
  get_discount_categories() {
    const discountCategories = [
      { key: 0, value: 'Descuento por reclamo', enum: 'complaint' },
      { key: 1, value: 'Terminal Las Condes', enum: 'terminal_las_condes' },
      { key: 2, value: 'Marketing', enum: 'marketing' },
      { key: 3, value: 'Correcciones', enum: 'adjustment' },
      { key: 4, value: 'Otros', enum: 'others' },
    ];
    return discountCategories;
  },
  get_account_types() {
    return [
      { key: 'current', value: 'Cuenta corriente' },
      { key: 'checking', value: 'Cuenta Vista o Cuenta RUT' },
      { key: 'savings', value: 'Cuenta de ahorro' },
      { key: 'checks', value: 'Cheques' }, // MX account type
      { key: 'deposit', value: 'Cuenta Depósitos' }, // MX account type
    ];
  },
  get_banks() {
    return [
      'BANCO DE CHILE', 'BANCO EDWARDS', 'CITIBANK', 'BANCO INTERNACIONAL',
      'BANCO ESTADO', 'SCOTIABANK', 'BANCO DESARROLLO',
      'BANCO SUD AMERICANO', 'BANCO CREDITO E INVERSIONES (BCI)',
      'CORPBANCA', 'BANCO BICE', 'BANCO SANTANDER', 'BANCO ITAU',
      'THE BANK OF TOKYO MITSUBISHI', 'ABN AMOR BANK',
      'BANCO SECURITY', 'BANCO FALABELLA', 'BANCO CONSORCIO',
      'BBVA', 'SCOTIABANK AZUL (ex BBVA)', 'COOPEUCH',
      'Santander México', 'BBVA Bancomer', 'Banorte', // MX banks
    ];
  },
  get_holiday_types() {
    const holidayTypes = [
      { key: 'Religioso', value: 'Religioso' },
      { key: 'Religioso e irrenunciable', value: 'Religioso e irrenunciable' },
      { key: 'Civil', value: 'Civil' },
      { key: 'Civil e irrenunciable', value: 'Civil e irrenunciable' },
    ];
    return holidayTypes;
  },
  TransferPolicyTypes() {
    return [
      { key: 'business_day', value: 'business_day' },
      { key: 'monthly_times', value: 'monthly_times' },
      { key: 'fixed', value: 'fixed' },
      { key: 'weekly', value: 'weekly' },
    ];
  },
  WeekDays() {
    return [
      { key: 'monday', value: 'Lunes' },
      { key: 'tuesday', value: 'Martes' },
      { key: 'wednesday', value: 'Miercoles' },
      { key: 'thursday', value: 'Jueves' },
      { key: 'friday', value: 'Viernes' },
      { key: 'saturday', value: 'Sábado' },
      { key: 'sunday', value: 'Domingo' },
    ];
  },
  ResponsibilityComplaint() {
    return [
      { key: 'true', value: 'Operador' },
      { key: 'false', value: 'Recorrido' },
      { key: 'coupon', value: 'Recorrido Cupón' },
    ];
  },
  GetRoles() {
    return [
      { display_name: 'Administrador', name: 'admin' },
      { display_name: 'Chief', name: 'chief' },
      { display_name: 'Agente Finanzas', name: 'finance_agent' },
      { display_name: 'Observer', name: 'observer' },
      { display_name: 'Superadministrador', name: 'superadmin' },
    ];
  },
  CancelationStates(view) {
    const cancelationStates = [
      { key: 'refunded', value: 'Devuelto' },
      { key: 'pending', value: 'Pendiente' },
      { key: 'processing', value: 'Procesando' },
      { key: 'rejected', value: 'Rechazado' },
      { key: 'excluded', value: 'Excluido' },
      { key: 'expired', value: 'Expirado' },
    ];
    if (view === 'general') {
      cancelationStates.splice(3, 0, { key: 'coupon_payment_form_changed', value: 'Cupon/cambio forma de pago' });
      cancelationStates.splice(4, 0, { key: 'complaint_refunded', value: 'Pagado por Reclamo' });
    }

    if (view === 'bank') {
      cancelationStates.splice(3, 0, { key: 'complaint_refunded', value: 'Pagado por reclamo' });
    }

    return cancelationStates;
  },
  TicketCancellationRefundMethods(view) {
    const refundMethods = [];

    if (view === 'paypal' || view === 'general') {
      refundMethods.splice(0, 0, { key: 'via_paypal', value: 'PayPal' });
      refundMethods.splice(1, 0, { key: 'via_payroll_paypal', value: 'Nómina paypal' });
    }

    if (view === 'bank' || view === 'general') {
      refundMethods.splice(0, 0, { key: 'via_bank_transfer', value: 'Nómina Banco' });
      refundMethods.splice(1, 0, { key: 'via_tbk_devolution', value: 'Reembolso TBK' });
      refundMethods.splice(2, 0, { key: 'via_oneclick', value: 'Reembolso OneClick' });
    }

    if (view === 'general') {
      refundMethods.splice(4, 0, { key: 'via_coupon', value: 'Cupón Reembolso' });
      refundMethods.splice(5, 0, { key: 'via_external_agency', value: 'Agencia' });
    }

    return refundMethods;
  },
  FormatDate(source = 'all') {
    const formatDates = [
      { key: 'daily', value: 'Diario' },
      { key: 'weekly', value: 'Semanal' },
      { key: 'monthly', value: 'Mensual' },
    ];
    if (source === 'all') {
      formatDates.push({ key: 'quarterly', value: 'Trimestral' });
      formatDates.push({ key: 'yearly', value: 'Anual' });
    }

    return formatDates;
  },
  FormatType() {
    return [
      { key: 'all', value: 'Todos' },
      { key: 'bill', value: 'Bill' },
      { key: 'credit', value: 'Credit' },
      { key: 'payment', value: 'Payment' },
    ];
  },
  SoldCurrencies(country) {
    const currencies = [{ key: 'Todos', value: '' }];

    switch (country) {
      case 'CL':
        currencies.push({ key: 'CLP', value: 'CLP' });
        break;
      case 'PE':
        currencies.push({ key: 'PEN', value: 'PEN' });
        break;
      case 'MX':
        currencies.push({ key: 'MXN', value: 'MXN' });
        break;
      default:
    }
    currencies.push({ key: 'USD', value: 'USD' });

    return currencies;
  },
  ComplaintCategories(country) {
    const categories = [
      { key: 'Todos', value: '' },
      { key: 'Preaprobado', value: 'Preaprobado' },
      { key: 'Bus no recoge a pasajero', value: 'Bus no recoge a pasajero' },
      { key: 'Asiento revendido', value: 'Asiento revendido' },
      { key: 'Suspensión / Paso cerrado', value: 'Suspensión / Paso cerrado' },
      { key: 'Transbank error', value: 'Transbank error' },
      { key: 'Servicio no cumplido', value: 'Servicio no cumplido' },
      { key: 'Problema con pasaje (impresión / auxiliar)', value: 'Problema con pasaje (impresión / auxiliar)' },
      { key: 'Error recorrido', value: 'Error recorrido' },
      { key: 'Devolución no realizada', value: 'Devolución no realizada' },
      { key: 'Busbud', value: 'Busbud' },
      { key: 'Latam Pass', value: 'Latam Pass' },
      { key: 'Ventanilla cerrada', value: 'Ventanilla cerrada' },
      { key: 'Error en anulación', value: 'Error en anulación' },
      { key: 'Otro', value: 'Otro' },
      { key: 'Reclamo cupón', value: 'Reclamo cupón' },
    ];

    switch (country) {
      case 'PE':
        categories.push({ key: 'Cancelación - Anulación de pasajes', value: 'Cancelación - Anulación de pasajes' });
        categories.push({ key: 'Error de Transacción PayU', value: 'Error de Transacción PayU' });
        break;
      default:
    }

    return categories;
  },
  ComplaintStates() {
    return [
      { key: '', value: 'Todos' },
      { key: 'pending_err', value: 'Pendiente decisión - Departamento ERR' },
      { key: 'pending_edb', value: 'Pendiente decisión - Empresa de bus' },
      { key: 'pending_info', value: 'Pendiente decisión - Pedir más información del cliente' },
      { key: 'pending_requested', value: 'Pendiente decisión - Información adicional solicitada' },
      { key: 'approved_communication', value: 'Aprobado - Pendiente comunicación al cliente' },
      { key: 'approved_bank', value: 'Aprobado - Pendiente envio de datos bancarios por parte del cliente' },
      { key: 'rejected_communication', value: 'Rechazado - Pendiente comunicación al cliente' },
      { key: 'approved_reimbursement', value: 'Aprobado - Pendiente devolución' },
      { key: 'edb_accepted', value: 'Aprobado por Empresa de bus' },
      { key: 'edb_rejected', value: 'Rechazado por Empresa de bus' },
      { key: 'edb_request', value: 'Pendiente decisión - Empresa de bus solicita información adicional' },
      { key: 'rejected', value: 'Resuelto - Rechazado' },
      { key: 'closed', value: 'Resuelto' },
      { key: 'reimbursement_error', value: 'Aprobado - Error en transferencia' },
      { key: 'expired', value: 'Expirado' },
      { key: 'approved_processing', value: 'Aprobado - En proceso de devolución' },
    ];
  },
  ComplaintPaymentMethod(country) {
    const paymentMethods = [{ key: 'Todos', value: '' }];

    switch (country) {
      case 'CL':
        paymentMethods.push({ key: 'WebPay', value: 'WebPay' }, { key: 'Fintoc', value: 'Fintoc' }, { key: 'OneClick', value: 'OneClick' }, { key: 'PayPal', value: 'PayPal' }, { key: 'Cupones', value: 'Cupones' });
        break;
      case 'PE':
        paymentMethods.push({ key: 'PayU', value: 'PayU' });
        break;
      case 'MX':
        paymentMethods.push({ key: 'PayU', value: 'PayU' }, { key: 'PayPal', value: 'PayPal' });
        break;
      default:
    }

    return paymentMethods;
  },
  ComplaintRefundMethod(country, view = 'general') {
    const refundMethods = [
      { key: '', value: 'Todos' },
    ];

    switch (country) {
      case 'CL':
        if (view === 'general' || view === 'bank') {
          refundMethods.push({ key: 'payroll_bank', value: 'Nómina Banco' });
          refundMethods.push({ key: 'devolution_oneclick', value: 'Reembolso OneClick' });
          refundMethods.push({ key: 'devolution_tbk', value: 'Reembolso TBK' });
        }
        if (view === 'paypal' || view === 'general') {
          refundMethods.push({ key: 'payroll_paypal', value: 'Nómina Paypal' });
          refundMethods.push({ key: 'devolution_paypal', value: 'Reembolso Paypal' });
        }
        break;
      case 'MX':
      case 'PE':
        if (view === 'payu' || view === 'general') {
          refundMethods.push({ key: 'payroll_payu', value: 'Nómina Payu' });
          refundMethods.push({ key: 'devolution_payu', value: 'Reembolso PayU' });
        }
        if (view === 'paypal' || view === 'general') {
          refundMethods.push({ key: 'devolution_paypal', value: 'Reembolso Paypal' });
        }
        break;
      default:
    }

    return refundMethods;
  },
  ConciliationCurrencies() {
    return ['MXN']; // only mexico for now
  },
};
