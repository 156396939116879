<template lang="pug">
  div
    el-row
      el-col
        h3.title Transferencias Bancarias - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Operadores: ")
            select-input(
              :options="filteredConciliables"
              :attribute="search"
              modelKey="operators"
              :multiple="true"
            )
          el-form-item(label="Rango Depósito:")
            date-input(:inputModel="search" modelKey="depositRange" type="daterange")
          el-form-item(label="Descripción:")
            el-input(v-model="search.description")
          el-form-item(label="Mes:")
            date-input(:inputModel="search" modelKey="salePeriod" type="month")
          el-form-item(label="Estado:")
            select-input(
              :options="transferStatuses"
              :attribute="search"
              modelKey="statuses"
              :multiple="true"
            )
          el-form-item(label="Titular:")
            el-input(v-model="search.holder")
          el-form-item(label="Número de Operación:")
            el-input(v-model="search.operationNumber")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchTransfers(pagination.current)"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportBankTransfers(pagination.current)"
            ) EXPORTAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-document-add"
              @click="handleCreateBankTransfer()"
            ) Nueva Transferencia Bancaría
    el-row
      el-col
        strong Total transferencias: {{ sumAmountTransfers }}
    el-row
      el-col
        data-table(
          :headers="headers"
          :items="bank_transfers"
          @update="handleUpdate"
          :loading="loading"
        )
        el-pagination(
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.perPage"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        )
    el-dialog(:title="dialogForm.title" :visible.sync="dialogForm.visible" width="70%")
      inline-form(:formAttributes="dialogForm.attributes" ref="dialogForm")
        template(slot="actions" slot-scope="scope")
          el-row.inline-form__actions
            template(v-if="dialogForm.data")
              el-button(
                type="danger"
                size="small"
                @click.prevent="updateBankTransfer(2)"
                :loading="submitLoading"
              ) Rechazar
              el-button(
                type="success"
                size="small"
                @click.prevent="updateBankTransfer(1)"
                :loading="submitLoading"
              ) Aprobar
            template(v-else)
              el-button(
                type="primary"
                size="small"
                @click.prevent="createBankTransfer()"
                :loading="submitLoading"
              ) Crear
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import InlineForm from '@/components/forms/InlineForm.vue';
import DataTable from '@/components/tables/DataTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';
import handleDownload from '@/utils/handleDownload';

// eslint-disable-next-line import/no-cycle
import financeApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';

// eslint-disable-next-line import/no-cycle
import conciliables from '@/mixins/conciliables';

import flattenForm from '@/utils/flattenForm';
import formatForm from '@/utils/formatForm';
import setPagination from '@/utils/setPagination';
import { currencyFormatter } from '@/utils/cellFormatter';

export default {
  name: 'BankTransfers',
  components: {
    SelectInput, InlineForm, DataTable, DateInput,
  },
  mixins: [
    conciliables,
  ],
  data() {
    return {
      search: {
        depositRange: [
          DateService.getDate(),
          DateService.getDate(),
        ],
        description: '',
        salePeriod: '',
        operators: [],
        holder: '',
        operationNumber: '',
        statuses: [],
        country: 'CL',
      },
      bank_transfers: [],
      pagination: {},
      dialogForm: { visible: false, attributes: {} },
      sumAmountTransfers: 0,
      selectedCountry: null,
      loading: false,
      submitLoading: false,
      channelMessage: '',
    };
  },
  beforeMount() {
    this.fetchTransfers(1);
  },
  methods: {
    fetchTransfers(page) {
      this.loading = true;
      financeApi.get_bank_transfers(page, this.search).then((data) => {
        this.bank_transfers = data.body.bank_transfers;
        this.sumAmountTransfers = currencyFormatter(
          Number(data.body.sum_amount_transfers), this.search.country,
        );

        setPagination(this, data, page);
        this.loading = false;
      }).catch(() => { this.loading = false; });
      this.selectedCountry = this.search.country;
    },
    exportBankTransfers(page) {
      financeApi.get_bank_transfers(page, this.search, true).then(() => {
        this.channelMessage = this.$message({
          type: 'success',
          message: 'Se está generando el archivo CSV, esto puede tardar unos minutos. Al cerrar el navegador o pestaña se perderá la descarga',
          duration: 0,
          showClose: false,
        });
      });
    },
    handleCurrentChange(page) {
      this.fetchTransfers(page);
    },
    handleCreateBankTransfer() {
      const attributes = this._.cloneDeep(this.formAttributes);
      this.dialogForm.title = 'Nueva Transferencia';
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = null;
      this.dialogForm.visible = true;
    },
    createBankTransfer() {
      this.startSubmitLoading();
      const { form } = this.$refs.dialogForm.$refs;

      form.validate((valid) => {
        if (valid) {
          const attributes = formatForm(null, flattenForm(this.dialogForm.attributes.rows));
          const conciliableData = this.dialogForm.attributes.conciliable;

          financeApi.createTransfer(attributes, conciliableData, this.selectedCountry).then(() => {
            this.fetchTransfers(this.pagination.current);
            this.dialogForm.visible = false;
            this.submitLoading = false;
          }).catch(() => { this.submitLoading = false; });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    handleUpdate(data) {
      const attributes = this._.cloneDeep(this.formAttributes);
      const rows = flattenForm(attributes.rows);

      rows.forEach((row) => {
        const rowValue = this._.get(data, row.key);
        this._.set(row, 'value', rowValue);
        if (row.key === 'bus_operator') { this._.set(row, 'modelKey', 'value'); }
        if (row.key !== 'date_range') { this._.set(row, 'disabled', true); }
        if (row.inputType === 'date') {
          this._.set(row, 'value', DateService.getDate(rowValue, 'YYYY-MM-DD'));
        }
      });

      this.dialogForm.title = 'Revisión de transferencia';
      this.dialogForm.attributes = attributes;
      this.dialogForm.data = { ...data };
      this.dialogForm.visible = true;
    },
    updateBankTransfer(newStatus) {
      this.startSubmitLoading();
      const { form } = this.$refs.dialogForm.$refs;

      form.validate((valid) => {
        if (valid) {
          const { id } = this.dialogForm.data;
          const attributes = formatForm(id, flattenForm(this.dialogForm.attributes.rows));

          financeApi.updateTransfer(id, {
            range_start: attributes.date_range[0],
            range_end: attributes.date_range[1],
            internal_status: newStatus,
          }, this.selectedCountry).then(() => {
            this.fetchTransfers(this.pagination.current);
            this.dialogForm.visible = false;
            this.submitLoading = false;
          }).catch(() => { this.submitLoading = false; });
        } else {
          this.stopSubmitLoading();
        }
      });
    },
    setNewValueToAttribute(data, rows, key, field) {
      const attribute = rows.find((row) => row.key === key);
      this._.set(attribute, 'value', this._.get(data, field));
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  computed: {
    headers() {
      return [
        { key: 'deposit_date', label: 'Fecha Depósito' },
        { key: 'description', label: 'Descripción' },
        { key: 'sale_period', label: 'Fecha de venta Año/Mes' },
        { key: 'bus_operator', label: 'Operador' },
        { key: 'holder', label: 'Titular' },
        { key: 'operation_number', label: 'Número de Operación' },
        {
          key: 'amount', label: 'Monto', type: 'currency', country: this.selectedCountry,
        },
        { key: 'internal_status', label: 'Estado' },
      ];
    },
    countries() {
      return DataService.get_countries();
    },
    selectedCountryName() {
      return this.countries.find((country) => country.key === this.selectedCountry).value;
    },
    transferStatuses() {
      return [
        { key: 'pending', value: 'Pendiente' },
        { key: 'approved', value: 'Aprobada' },
        { key: 'cancelled', value: 'Descartada' },
      ];
    },
    formAttributes() {
      const $this = this;
      return {
        conciliable: { id: null, klass: '' },
        rows: [
          [
            {
              key: 'bus_operator',
              value: null,
              inputType: 'select',
              label: 'Operador',
              options: this.filteredConciliables,
              optionKey: 'idKey',
              changeMethod(idKey) {
                const foundConciliable = $this.conciliables
                  .find((conciliable) => `${conciliable.id}-${conciliable.klass}` === idKey);
                const rows = flattenForm($this.dialogForm.attributes.rows);
                const conciliableData = $this.dialogForm.attributes.conciliable;

                this._.set(conciliableData, 'id', foundConciliable.id);
                this._.set(conciliableData, 'klass', foundConciliable.klass);

                $this.setNewValueToAttribute(foundConciliable, rows, 'holder', 'holder');
                $this.setNewValueToAttribute(foundConciliable, rows, 'bank_name', 'bank_name');
                $this.setNewValueToAttribute(foundConciliable, rows, 'account_number', 'number');
              },
            },
            {
              key: 'description', value: null, inputType: 'string', label: 'Descripción',
            },
          ],
          [
            {
              key: 'holder', value: null, inputType: 'string', label: 'Titular', disabled: true,
            },
            {
              key: 'bank_name', value: null, inputType: 'string', label: 'Banco', disabled: true,
            },
            {
              key: 'account_number', value: null, inputType: 'string', label: 'Cuenta', disabled: true,
            },
          ],
          [
            {
              key: 'operation_number', value: null, inputType: 'string', label: 'Nº de operación',
            },
            {
              key: 'amount', value: 0, inputType: 'currency', label: 'Monto', country: this.search.country,
            },
          ],
          [
            {
              key: 'deposit_date', value: null, inputType: 'date', label: 'Fecha de depósito',
            },
            {
              key: 'date_range', value: null, inputType: 'daterange', label: 'Entre los días',
            },
          ],
        ],
      };
    },
  },
  channels: {
    PrivateChannel: {
      connected() { },
      rejected() { },
      received(data) {
        if (data.status === 'error') {
          setTimeout(() => {
            this.channelMessage.close();

            this.$message({
              type: 'error',
              message: 'Falló la descarga',
              duration: 7000,
              showClose: false,
            });
          }, 3000);
        } else {
          const blob = new Blob([data.file_content], { type: 'text/csv' });

          const simulatedResponse = {
            blob: async () => blob,
            headers: {
              get: (header) => {
                if (header === 'content-disposition') {
                  return `attachment; filename="${data.filename}.csv"`;
                }
                return null;
              },
            },
          };

          handleDownload(simulatedResponse);
          setTimeout(() => {
            this.channelMessage.close();
          }, 3000);
        }
      },
      disconnected() { },
    },
  },
  mounted() {
    this.$cable.subscribe({ channel: 'PrivateChannel', user_email: store.state.user.email, origin: 'bank_transfers' });
  },
  destroyed() {
    const identifier = JSON.stringify({
      channel: 'PrivateChannel',
      user_email: store.state.user.email,
      origin: 'bank_transfers',
    });
    /* eslint-disable no-underscore-dangle */
    const subscription = this.$cable._cable.subscriptions.subscriptions
      .find((sub) => sub.identifier === identifier);
    /* eslint-enable no-underscore-dangle */
    subscription.unsubscribe();
  },
};
</script>
