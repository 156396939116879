<template lang="pug">
div
  template(v-if="data.length > 0")
    el-badge(:value="data.length" class="item")
      el-button.push-right(
        type="warning"
        icon="el-icon-download"
        @click="drawer = true"
      ) Procesos en cola
  template(v-else)
    el-button.push-right(
      type="warning"
      icon="el-icon-download"
      @click="drawer = true"
    ) Procesos en cola
  el-drawer(
    title="Procesos en cola"
    :visible.sync="drawer"
    :direction="direction"
  )
    template(v-for="item in data")
      el-card.__download-queue
        div(slot="header")
          el-row.card_header(justify="space-between")
            el-col(:span="19") {{ item.origin }}
            el-col(:span="4" style="display: flex; justify-content: flex-end;")
              el-tag.status(
                :type="statusTagColor(item.status)"
                size="small"
              ) {{ statusTagName(item.status) }}
        div.card_body
          el-descriptions(:column="1")
            el-descriptions-item(label="Fecha de solicitud")
              el-tag(type="info" size="small") {{ item.date }}
            el-descriptions-item(label="Responsable") {{ item.responsible }}
</template>

<script>
export default {
  name: 'DownloadQueue',
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      data: [],
    };
  },
  methods: {
    statusTagColor(status) {
      let color = '';
      switch (status) {
        case 'in_progress':
          color = 'default';
          break;
        case 'completed':
          color = 'success';
          break;
        case 'error':
          color = 'danger';
          break;
        default:
          color = 'info';
          break;
      }
      return color;
    },
    statusTagName(status) {
      let name = '';
      switch (status) {
        case 'in_progress':
          name = 'En progreso';
          break;
        case 'completed':
          name = 'Completado';
          break;
        case 'error':
          name = 'Error';
          break;
        default:
          name = 'Info';
          break;
      }
      return name;
    },
    removeCompletedItem(origin, responsible, date) {
      setTimeout(() => {
        const index = this.data.findIndex(
          (item) => item.origin === origin && item.responsible === responsible
                      && item.date === date,
        );
        if (index !== -1) {
          this.data.splice(index, 1);
        }
      }, 5000);
    },
  },
  channels: {
    GeneralChannel: {
      connected() { },
      received(data) {
        const index = this.data.findIndex(
          (item) => item.origin === data.origin && item.responsible === data.email
          && item.date === data.date,
        );

        if (index !== -1) {
          this.$set(this.data, index, {
            ...this.data[index],
            status: data.status,
            date: data.date,
          });

          if (data.status === 'completed') {
            this.removeCompletedItem(data.origin, data.email, data.date);
          }
        } else {
          this.data.push({
            origin: data.origin,
            status: data.status,
            responsible: data.email,
            date: data.date,
          });

          if (data.status === 'completed') {
            this.removeCompletedItem(data.origin, data.email, data.date);
          }
        }
      },
      rejected() { },
      disconnected() { },
    },
  },
  mounted() {
    this.$cable.subscribe({ channel: 'GeneralChannel' });
  },
};
</script>
<style scoped>
.push-right {
  margin-left: auto;
}
.card_header {
  display: flex;
  position: relative;
}
.el-card.__download-queue {
  margin: 12px;
}
.card_body {
  padding: 3px;
}
.status {
  margin-left: 5px;
}
</style>
