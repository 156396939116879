<template lang="pug">
  div
    tab-menu
    div.container
      el-row
        el-col(:span="24")
          h1 Sistema de finanzas
        el-col(:span="24")
          img(src="@/assets/images/home.jpeg" class="img-home")
</template>

<script>
import TabMenu from '@/components/layout/TabMenu.vue';

export default {
  name: 'Home',
  components: {
    TabMenu,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

div {
  width: 100%;
}

h1 {
  margin-left: 20px;
  margin-top: 10px;
}

.img-home {
  max-width: 50%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}
</style>
